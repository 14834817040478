import {
  Scissors, Building2, Wrench, Car, Utensils, Hotel,
  Dumbbell, Heart, ShoppingBag, Calculator, Scale, ChefHat
} from 'lucide-react';
import { Industry } from '../types';

export const industries: Industry[] = [
  {
    id: 'barbershop',
    title: 'Barbershop',
    description: 'Professionelle Website für Ihren Friseursalon mit Online-Terminbuchung. ✓ Terminkalender ✓ Kundenmanagement ✓ Bildergalerie',
    icon: Scissors,
    backgroundImage: 'https://images.unsplash.com/photo-1503951914875-452162b0f3f1?auto=format&fit=crop&q=80',
    features: ['Online-Terminbuchung', 'Kundenmanagement', 'Bildergalerie']
  },
  {
    id: 'construction',
    title: 'Baufirma',
    description: 'Repräsentative Website für Ihr Bauunternehmen mit Projektreferenzen. ✓ Projektgalerie ✓ Leistungsübersicht ✓ Kontaktformular',
    icon: Building2,
    backgroundImage: 'https://images.unsplash.com/photo-1504307651254-35680f356dfd?auto=format&fit=crop&q=80',
    features: ['Projektgalerie', 'Leistungsübersicht', 'Kontaktformular']
  },
  {
    id: 'technician',
    title: 'Haustechnik',
    description: 'Professionelle Website für Ihren Installationsbetrieb. ✓ 24/7 Notdienst ✓ Online-Anfragen ✓ Servicebereiche',
    icon: Wrench,
    backgroundImage: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?auto=format&fit=crop&q=80',
    features: ['24/7 Notdienst', 'Online-Anfragen', 'Servicebereiche']
  },
  {
    id: 'auto',
    title: 'KFZ-Werkstatt',
    description: 'Moderne Website für Ihre Autowerkstatt mit Online-Terminbuchung. ✓ Servicetermine ✓ Leistungen ✓ Reifenservice',
    icon: Car,
    backgroundImage: 'https://images.unsplash.com/photo-1486262715619-67b85e0b08d3?auto=format&fit=crop&q=80',
    features: ['Online-Terminbuchung', 'Leistungsübersicht', 'Reifenservice']
  },
  {
    id: 'restaurant',
    title: 'Restaurant',
    description: 'Appetitanregende Website für Ihr Restaurant mit Online-Reservierung. ✓ Speisekarte ✓ Tischreservierung ✓ Bildergalerie',
    icon: Utensils,
    backgroundImage: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?auto=format&fit=crop&q=80',
    features: ['Online-Reservierung', 'Digitale Speisekarte', 'Eventbuchung']
  },
  {
    id: 'hotel',
    title: 'Hotel & Pension',
    description: 'Einladende Website für Ihr Hotel mit Online-Buchungssystem. ✓ Zimmerbuchung ✓ Bildergalerie ✓ Veranstaltungen',
    icon: Hotel,
    backgroundImage: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?auto=format&fit=crop&q=80',
    features: ['Online-Buchung', 'Zimmerkategorien', 'Veranstaltungen']
  },
  {
    id: 'doner',
    title: 'Döner Imbiss',
    description: 'Moderne Website für Ihren Döner Imbiss mit Online-Bestellung. ✓ Online-Bestellung ✓ Digitale Speisekarte ✓ Lieferservice',
    icon: ChefHat,
    backgroundImage: 'https://images.unsplash.com/photo-1565299507177-b0ac66763828?auto=format&fit=crop&q=80',
    features: ['Online-Bestellung', 'Digitale Speisekarte', 'Lieferservice']
  },
  {
    id: 'fitness',
    title: 'Fitnessstudio',
    description: 'Dynamische Website für Fitnesscenter mit Kursplan und Mitgliedschafts-Management. ✓ Online-Kursbuchung ✓ Mitgliederverwaltung ✓ Trainingsplan',
    icon: Dumbbell,
    backgroundImage: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&q=80',
    features: ['Online-Kursbuchung', 'Mitgliederverwaltung', 'Trainingsplan']
  },
  {
    id: 'medical',
    title: 'Arztpraxis',
    description: 'Vertrauenserweckende Website für Ärzte mit Online-Terminbuchung und Patientenportal. ✓ Terminbuchung ✓ Patientenportal ✓ Sprechzeiten',
    icon: Heart,
    backgroundImage: 'https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&q=80',
    features: ['Terminbuchung', 'Patientenportal', 'Sprechzeiten']
  },
  {
    id: 'retail',
    title: 'Einzelhandel',
    description: 'E-Commerce Lösungen für den lokalen Einzelhandel mit Online-Shop und Click & Collect. ✓ Online-Shop ✓ Produktkatalog ✓ Click & Collect',
    icon: ShoppingBag,
    backgroundImage: 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80',
    features: ['Online-Shop', 'Produktkatalog', 'Click & Collect']
  },
  {
    id: 'tax',
    title: 'Steuerkanzlei',
    description: 'Professionelle Website für Steuerberater mit sicherem Mandantenportal. ✓ Mandantenportal ✓ Dokumentenupload ✓ Terminvereinbarung',
    icon: Calculator,
    backgroundImage: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?auto=format&fit=crop&q=80',
    features: ['Mandantenportal', 'Dokumentenupload', 'Terminvereinbarung']
  },
  {
    id: 'law',
    title: 'Rechtsanwaltskanzlei',
    description: 'Seriöse Online-Präsenz für Rechtsanwälte mit Fachinformationen und Fallbewertung. ✓ Fallbewertung ✓ Rechtsgebiete ✓ Kontaktformular',
    icon: Scale,
    backgroundImage: 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80',
    features: ['Fallbewertung', 'Rechtsgebiete', 'Kontaktformular']
  }
];