import React from 'react';
import { motion } from 'framer-motion';
import PricingCard from './PricingCard';

const pricingPlans = [
  {
    title: 'Onepage Design',
    price: '€749,-',
    features: [
      'Responsive Design',
      'SEO Optimierung',
      'Kontaktformular',
      'Analytics Integration',
      'Hosting Setup',
      '3 Revisionsrunden'
    ]
  },
  {
    title: 'Business Website Standard',
    price: '€1.495,-',
    features: [
      'Alles aus Onepage Design, plus:',
      'Bis zu 5 Unterseiten',
      'Blog-System',
      'Newsletter Integration',
      'Cookie-Banner',
      '3 Revisionsrunden'
    ],
    isPopular: true
  },
  {
    title: 'Business Website Enterprise',
    price: '€2.950,-',
    features: [
      'Alles aus Business Standard, plus:',
      'Bis zu 10 Unterseiten',
      'Mehrsprachigkeit',
      'Premium Support',
      'Content Management System',
      '3 Revisionsrunden'
    ]
  },
  {
    title: 'Managed Webhosting',
    price: '€49,-',
    subtitle: 'pro Monat',
    features: [
      'SSL-Zertifikat',
      'Tägliche Backups',
      'Security Updates',
      'Performance Monitoring',
      'Monatlich 1h Support',
      'E-Mail Support'
    ]
  }
];

const Pricing = () => {
  return (
    <section id="pricing" className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6">
            Transparente Preise für die Erstellung ihrer neuen Webseite
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Professionelle Lösungen für jeden Bedarf
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {pricingPlans.map((plan, index) => (
            <PricingCard
              key={plan.title}
              title={plan.title}
              price={plan.price}
              features={plan.features}
              isPopular={plan.isPopular}
              delay={index * 0.1}
              subtitle={plan.subtitle}
            />
          ))}
        </div>

        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          viewport={{ once: true }}
          className="text-center text-gray-500 mt-12"
        >
          * Alle Preise zzgl. gesetzlicher MwSt.
        </motion.p>
      </div>
    </section>
  );
};

export default Pricing;