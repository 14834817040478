import React from 'react';
import { motion } from 'framer-motion';
import { Scissors } from 'lucide-react';

const services = [
  {
    title: "Haarschnitt",
    price: "€35",
    description: "Präzise Schnitte mit Stil"
  },
  {
    title: "Bart Styling",
    price: "€25",
    description: "Professionelle Bartpflege"
  },
  {
    title: "Komplett-Service",
    price: "€55",
    description: "Haar- und Bartpflege"
  }
];

const ServicesSection = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Unsere Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-50 p-8 rounded-xl text-center"
            >
              <div className="inline-block p-4 bg-black text-white rounded-full mb-4">
                <Scissors className="w-8 h-8" />
              </div>
              <h3 className="text-2xl font-bold mb-2">{service.title}</h3>
              <p className="text-3xl font-bold text-primary mb-4">{service.price}</p>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;