import React, { useState, useEffect } from 'react';
import { Menu, X, Globe, Search, ArrowUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Navigation from '../Navigation/Navigation';
import ScrollToTop from '../Navigation/ScrollToTop';
import '../../styles/header.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [language, setLanguage] = useState('DE');

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header 
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? 'header-glass header-shadow' : 'bg-transparent'
        }`}
        role="banner"
      >
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <a 
              href="/" 
              className="flex items-center py-2" 
              aria-label="Zur Startseite"
            >
              <img 
                src="https://r2.hoefler-sbk.de/Bilder_Webseite/Webdesign.png"
                alt="Höfler Webdesign Logo"
                className="h-12 w-auto"
              />
            </a>
            
            <Navigation />
            
            <div className="hidden md:flex items-center space-x-4">
              <button
                onClick={() => setLanguage(language === 'DE' ? 'EN' : 'DE')}
                className="flex items-center space-x-1 text-gray-800 hover:text-primary transition-colors"
                aria-label={`Sprache ändern zu ${language === 'DE' ? 'Englisch' : 'Deutsch'}`}
              >
                <Globe size={20} />
                <span>{language}</span>
              </button>
              
              <button 
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                aria-label="Suche öffnen"
              >
                <Search size={20} />
              </button>
            </div>

            <button 
              className="md:hidden p-2"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen}
              aria-label="Menü"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden bg-white shadow-lg"
              role="dialog"
              aria-label="Mobile Navigation"
            >
              <nav className="container mx-auto px-4 py-4">
                <ul className="space-y-4">
                  <li>
                    <a
                      href="/"
                      className="block py-2 text-gray-800 hover:text-primary transition-colors"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Home
                    </a>
                  </li>
                  {['Portfolio', 'Leistungen', 'Kontakt'].map((item) => (
                    <li key={item}>
                      <a
                        href={`#${item.toLowerCase()}`}
                        className="block py-2 text-gray-800 hover:text-primary transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </header>
      <ScrollToTop />
    </>
  );
};

export default Header;