import React from 'react';
import { motion } from 'framer-motion';

const benefits = [
  '15+ Jahre Expertise',
  '100% individuelle Lösungen',
  'Hosting in Österreich'
];

const HeroBenefits = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      className="lg:text-right text-center"
    >
      <div className="bg-white/10 backdrop-blur-md rounded-xl p-6 border border-white/20">
        <ul className="space-y-4 text-white">
          {benefits.map((benefit, index) => (
            <li key={index} className="flex items-center justify-end gap-3">
              <span>{benefit}</span>
              <span className="w-2 h-2 bg-white rounded-full"></span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default HeroBenefits;