interface ImageDimensions {
  width: number;
  height: number;
}

export const getOptimalImageDimensions = (
  containerWidth: number,
  containerHeight: number,
  imageRatio: number
): ImageDimensions => {
  let width = containerWidth;
  let height = containerWidth / imageRatio;

  if (height < containerHeight) {
    height = containerHeight;
    width = containerHeight * imageRatio;
  }

  return { width, height };
};

export const generateSrcSet = (baseUrl: string, sizes: number[]): string => {
  return sizes
    .map((size) => `${baseUrl}?w=${size} ${size}w`)
    .join(', ');
};

export const getImageLoadingPriority = (
  viewport: DOMRect,
  imageRect: DOMRect
): 'high' | 'low' => {
  const isAboveFold = imageRect.top < viewport.height;
  return isAboveFold ? 'high' : 'low';
};

export const preloadCriticalImages = (images: string[]): void => {
  images.forEach((src) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = src;
    document.head.appendChild(link);
  });
};