import React from 'react';
import { motion } from 'framer-motion';
import RestaurantHero from './sections/RestaurantHero';
import MenuSection from './sections/MenuSection';
import ReservationSection from './sections/ReservationSection';
import ReviewsSection from './sections/ReviewsSection';

export const RestaurantExample: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      <RestaurantHero />
      <MenuSection />
      <ReviewsSection />
      <ReservationSection />
    </div>
  );
};

export default RestaurantExample;