import React from 'react';
import Background from './VideoBackground';
import HeroHeading from './HeroHeading';
import HeroBenefits from './HeroBenefits';
import HeroCallToAction from './HeroCallToAction';
import ScrollIndicator from './ScrollIndicator';
import { usePerformanceMonitoring } from '../../utils/analytics';
import { getOptimalImageDimensions } from '../../utils/imageOptimizer';

const Hero = () => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  usePerformanceMonitoring();

  React.useEffect(() => {
    const updateDimensions = () => {
      const container = document.querySelector('.hero-container');
      if (container) {
        const rect = container.getBoundingClientRect();
        const optimal = getOptimalImageDimensions(rect.width, rect.height, 16/9);
        setDimensions(optimal);
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <section className="hero-container relative min-h-screen flex items-center justify-center overflow-hidden">
      <Background 
        imageUrl="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80"
        width={dimensions.width}
        height={dimensions.height}
      />
      
      <div className="absolute inset-0 bg-gradient-to-r from-primary/80 to-primary/40" />
      
      <div className="relative container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center min-h-[80vh]">
          <HeroHeading />
          <HeroBenefits />
          <HeroCallToAction />
        </div>
      </div>

      <ScrollIndicator />
    </section>
  );
};

export default Hero;