import React from 'react';
import { useForm } from './FormContext';
import { cn } from '../../utils/cn';

const StepIndicator = () => {
  const { currentStep } = useForm();

  return (
    <div className="flex justify-between mb-8">
      {[1, 2, 3, 4, 5, 6, 7].map(step => (
        <div
          key={step}
          className={cn(
            "w-8 h-8 rounded-full flex items-center justify-center transition-colors",
            step === currentStep && "bg-blue-600 text-white",
            step < currentStep && "bg-green-500 text-white",
            step > currentStep && "bg-gray-200"
          )}
        >
          {step < currentStep ? '✓' : step}
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;