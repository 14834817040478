import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const UserCenteredDesign = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDeviceChange = (device: string) => {
    const newDevices = formData.userDevices.includes(device)
      ? formData.userDevices.filter(d => d !== device)
      : [...formData.userDevices, device];
    setFormData({ ...formData, userDevices: newDevices });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">User Centered Design</h3>
      
      <FormField
        label="Wer ist Ihre primäre Zielgruppe?"
        name="targetAudience"
        value={formData.targetAudience}
        onChange={handleChange}
        type="textarea"
        placeholder="Beschreiben Sie Ihre idealen Nutzer..."
        error={errors.targetAudience}
        required
      />
      
      <div className="mb-4">
        <label className="block font-medium mb-2">
          Auf welchen Geräten wird Ihre Website hauptsächlich genutzt?
        </label>
        <div className="space-y-2">
          {['Desktop', 'Tablet', 'Smartphone'].map(device => (
            <label key={device} className="flex items-center">
              <input
                type="checkbox"
                checked={formData.userDevices.includes(device)}
                onChange={() => handleDeviceChange(device)}
                className="mr-2"
              />
              {device}
            </label>
          ))}
        </div>
      </div>
      
      <FormField
        label="Was sind die wichtigsten Bedürfnisse Ihrer Nutzer?"
        name="userNeeds"
        value={formData.userNeeds}
        onChange={handleChange}
        type="textarea"
        placeholder="Welche Probleme lösen Sie für Ihre Nutzer?"
        error={errors.userNeeds}
        required
      />
    </div>
  );
};

export default UserCenteredDesign;