import React from 'react';
import { motion } from 'framer-motion';
import { Coffee, Pizza, Wine, Salad } from 'lucide-react';

const menuCategories = [
  { icon: Coffee, title: 'Frühstück', description: 'Täglich 6-11 Uhr' },
  { icon: Pizza, title: 'Hauptspeisen', description: 'Hausgemachte Spezialitäten' },
  { icon: Wine, title: 'Getränke', description: 'Erlesene Weinkarte' },
  { icon: Salad, title: 'Desserts', description: 'Süße Versuchungen' }
];

const MenuSection: React.FC = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Unsere Speisekarte</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {menuCategories.map((category, index) => (
            <motion.div
              key={category.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-50 p-8 rounded-xl text-center"
            >
              <category.icon className="w-12 h-12 mx-auto mb-4 text-primary" />
              <h3 className="text-xl font-bold mb-2">{category.title}</h3>
              <p className="text-gray-600">{category.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MenuSection;