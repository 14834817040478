import React from 'react';
import { motion } from 'framer-motion';
import { Building2, Home, Factory, Wrench } from 'lucide-react';

const services = [
  {
    icon: Building2,
    title: "Gewerbebau",
    description: "Schlüsselfertige Gewerbeimmobilien nach Ihren Anforderungen"
  },
  {
    icon: Home,
    title: "Wohnbau",
    description: "Moderne Wohnprojekte mit höchstem Wohnkomfort"
  },
  {
    icon: Factory,
    title: "Industriebau",
    description: "Funktionale Industriebauten für optimale Prozesse"
  },
  {
    icon: Wrench,
    title: "Sanierung",
    description: "Professionelle Sanierung von Bestandsgebäuden"
  }
];

const ServicesSection = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Unsere Leistungen</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <service.icon className="w-12 h-12 text-primary mb-4" />
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;