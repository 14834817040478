import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const Competition = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Konkurrenzanalyse</h3>
      
      <FormField
        label="Wer sind Ihre Hauptwettbewerber?"
        name="mainCompetitors"
        value={formData.mainCompetitors}
        onChange={handleChange}
        type="textarea"
        placeholder="Nennen Sie die wichtigsten Konkurrenten mit Websites..."
        error={errors.mainCompetitors}
        required
      />
      
      <FormField
        label="Was machen Ihre Wettbewerber besonders gut?"
        name="competitorStrengths"
        value={formData.competitorStrengths}
        onChange={handleChange}
        type="textarea"
        placeholder="Stärken der Konkurrenz-Websites..."
      />
      
      <FormField
        label="Wo sehen Sie Schwächen bei der Konkurrenz?"
        name="competitorWeaknesses"
        value={formData.competitorWeaknesses}
        onChange={handleChange}
        type="textarea"
        placeholder="Schwachpunkte der Konkurrenz-Websites..."
      />

      <FormField
        label="Was ist Ihr Alleinstellungsmerkmal?"
        name="uniqueAdvantage"
        value={formData.uniqueAdvantage}
        onChange={handleChange}
        type="textarea"
        placeholder="Was macht Sie einzigartig?"
        error={errors.uniqueAdvantage}
        required
      />
    </div>
  );
};

export default Competition;