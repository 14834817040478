import React from 'react';
import { Building2 } from 'lucide-react';
import HeroSection from '../shared/HeroSection';
import ProjectsSection from './ProjectsSection';
import ServicesSection from './ServicesSection';
import ProcessSection from './ProcessSection';
import ContactSection from '../shared/ContactSection';

export const ConstructionExample: React.FC = () => {
  return (
    <div className="min-h-screen">
      <HeroSection
        title="Bau & Projektmanagement"
        subtitle="Ihr verlässlicher Partner für anspruchsvolle Bauprojekte"
        backgroundImage="https://images.unsplash.com/photo-1504307651254-35680f356dfd?auto=format&fit=crop&q=80"
        ctaText="Kostenlose Beratung"
        icon={<Building2 className="w-8 h-8 text-white" />}
      />
      <ProjectsSection />
      <ServicesSection />
      <ProcessSection />
      <ContactSection
        phone="+43 664 123 45 67"
        email="info@bau-example.at"
        address="Baustraße 1, 1100 Wien"
        ctaText="Projekt besprechen"
      />
    </div>
  );
};