import React from 'react';
import { motion } from 'framer-motion';

interface HeroSectionProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
  ctaText?: string;
  onCtaClick?: () => void;
  icon?: React.ReactNode;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  backgroundImage,
  ctaText = "Jetzt anfragen",
  onCtaClick,
  icon
}) => {
  return (
    <div className="relative h-screen">
      <img 
        src={backgroundImage}
        alt={title}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black/50" />
      
      <div className="absolute inset-0 flex items-center justify-center text-white">
        <div className="max-w-4xl px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {icon && (
              <div className="flex justify-center mb-6">
                <div className="p-4 bg-white/10 rounded-full backdrop-blur-sm">
                  {icon}
                </div>
              </div>
            )}
            <h1 className="text-5xl md:text-7xl font-bold mb-6">
              {title}
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              {subtitle}
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onCtaClick}
              className="bg-white text-primary px-8 py-3 rounded-lg font-semibold text-lg"
            >
              {ctaText}
            </motion.button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;