import React from 'react';
import { motion } from 'framer-motion';
import { Industry } from './types';
import { useTilt } from './hooks/useTilt';

interface IndustryCardProps {
  industry: Industry;
  onClick: () => void;
}

const IndustryCard: React.FC<IndustryCardProps> = ({ industry, onClick }) => {
  const { tiltRef, transform } = useTilt();

  return (
    <motion.div
      ref={tiltRef}
      style={{ transform }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      className="group relative overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer h-[400px]"
      onClick={onClick}
    >
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={industry.backgroundImage}
          alt={industry.title}
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
      </div>

      {/* Icon Badge */}
      <div className="absolute top-4 left-4 p-3 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg">
        <industry.icon className="w-6 h-6 text-primary" />
      </div>

      {/* Content */}
      <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
        <h3 className="text-2xl font-bold mb-3">{industry.title}</h3>
        <p className="text-gray-200 text-sm mb-4 line-clamp-3 group-hover:line-clamp-none transition-all duration-300">
          {industry.description}
        </p>
        
        {/* Features List */}
        <ul className="space-y-2 mb-4">
          {industry.features.map((feature, index) => (
            <li key={index} className="flex items-center text-sm text-gray-300">
              <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2"></span>
              {feature}
            </li>
          ))}
        </ul>

        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="inline-flex items-center px-4 py-2 bg-white text-primary rounded-lg font-medium text-sm hover:bg-primary hover:text-white transition-colors duration-300"
        >
          Beispiel ansehen
          <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M5 12h14M12 5l7 7-7 7"/>
          </svg>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default IndustryCard;