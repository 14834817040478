import React from 'react';
import { motion } from 'framer-motion';
import ImpressumSection from './ImpressumSection';
import ContactInfo from './ContactInfo';
import CompanyInfo from './CompanyInfo';
import DisputeResolution from './DisputeResolution';
import LegalNotices from './LegalNotices';

const Impressum = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4 max-w-4xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl font-bold mb-8">Impressum</h1>
          <div className="prose prose-lg max-w-none">
            <div className="mb-8">
              <a 
                href="https://www.wkoecg.at/Ecg.aspx?FirmaID=62130076-b9d1-400f-a92a-2a1d653ce493"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block hover:opacity-90 transition-opacity"
              >
                <img 
                  src="https://r2.hoefler-sbk.de/ecg.jpg.webp" 
                  alt="ECG Gütesiegel" 
                  className="max-w-[200px] h-auto"
                />
              </a>
            </div>

            <p className="text-xl text-gray-600 mb-12">
              Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, § 63 GewO und § 14 UGB
            </p>

            <ImpressumSection title="Webseitenbetreiber" delay={0.1}>
              <p className="text-gray-700">Andreas Höfler Webdesign</p>
            </ImpressumSection>

            <ImpressumSection title="Anschrift" delay={0.2}>
              <ContactInfo />
            </ImpressumSection>

            <ImpressumSection title="Unternehmensdaten" delay={0.3}>
              <CompanyInfo />
            </ImpressumSection>

            <ImpressumSection title="Online Streitbeilegung" delay={0.4}>
              <DisputeResolution />
            </ImpressumSection>

            <ImpressumSection title="Rechtliche Hinweise" delay={0.5}>
              <LegalNotices />
            </ImpressumSection>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Impressum;