import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';

const reviews = [
  {
    text: "Ein unvergesslicher Aufenthalt mit atemberaubendem Ausblick!",
    author: "Familie Müller",
    rating: 5,
    location: "Deutschland"
  },
  {
    text: "Hervorragender Service und exzellente Küche.",
    author: "Peter K.",
    rating: 5,
    location: "Österreich"
  },
  {
    text: "Perfekte Lage für Wanderungen und Wellness.",
    author: "Sarah M.",
    rating: 5,
    location: "Schweiz"
  }
];

const ReviewsSection: React.FC = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Gästebewertungen</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {reviews.map((review, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-50 p-6 rounded-xl"
            >
              <div className="flex text-yellow-400 mb-4">
                {[...Array(review.rating)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 fill-current" />
                ))}
              </div>
              <p className="text-gray-600 mb-4">"{review.text}"</p>
              <div>
                <p className="font-bold">{review.author}</p>
                <p className="text-sm text-gray-500">aus {review.location}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;