import React from 'react';
import { Building2, FileText } from 'lucide-react';

const CompanyInfo = () => {
  return (
    <div className="space-y-3">
      <div className="flex items-center space-x-3">
        <Building2 className="w-5 h-5 text-primary" />
        <div>
          <p>UID-Nr: ATU80920229</p>
          <p>Gewerbeaufsichtbehörde: Bezirkshauptmannschaft Schärding</p>
          <p>Mitgliedschaften: Wirtschaftskammer Oberösterreich</p>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <FileText className="w-5 h-5 text-primary" />
        <a 
          href="https://www.ris.bka.gv.at" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:text-primary transition-colors"
        >
          Anwendbare Rechtsvorschrift: www.ris.bka.gv.at
        </a>
      </div>
    </div>
  );
};

export default CompanyInfo;