import React from 'react';
import HeroSection from './sections/HeroSection';
import RoomSection from './sections/RoomSection';
import AmenitiesSection from './sections/AmenitiesSection';
import ReviewsSection from './sections/ReviewsSection';

export const HotelExample: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      <HeroSection />
      <RoomSection />
      <AmenitiesSection />
      <ReviewsSection />
    </div>
  );
};

export default HotelExample;