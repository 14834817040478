import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import MainLayout from './components/Layout/MainLayout';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import Industries from './components/Industries/Industries';
import Process from './components/Process/Process';
import Pricing from './components/Pricing/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer/Footer';
import Impressum from './components/Legal/Impressum/Impressum';
import Datenschutz from './components/Datenschutz';
import NotFound from './components/NotFound';
import CookieBanner from './components/CookieBanner';
import OnboardingForm from './components/Onboarding/OnboardingForm';

const App: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <MainLayout>
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Services />
              <Industries />
              <Process />
              <Pricing />
              <Contact />
            </>
          } />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/onboarding/:token" element={<OnboardingForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
      <Footer />
      <CookieBanner />
    </div>
  );
};

export default App;