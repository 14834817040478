import React from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin } from 'lucide-react';

interface ContactSectionProps {
  phone?: string;
  email?: string;
  address?: string;
  ctaText?: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({
  phone = "+43 123 456 789",
  email = "kontakt@example.at",
  address = "Musterstraße 1, 1010 Wien",
  ctaText = "Jetzt Termin vereinbaren"
}) => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg p-8"
          >
            <h2 className="text-3xl font-bold text-center mb-8">Kontakt</h2>
            
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                {[
                  { icon: Phone, text: phone, href: `tel:${phone}` },
                  { icon: Mail, text: email, href: `mailto:${email}` },
                  { icon: MapPin, text: address }
                ].map(({ icon: Icon, text, href }) => (
                  <div key={text} className="flex items-center space-x-4">
                    <Icon className="w-6 h-6 text-primary" />
                    {href ? (
                      <a href={href} className="hover:text-primary transition-colors">
                        {text}
                      </a>
                    ) : (
                      <span>{text}</span>
                    )}
                  </div>
                ))}
              </div>

              <div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full bg-primary text-white py-3 rounded-lg font-semibold hover:bg-primary/90 transition-colors"
                >
                  {ctaText}
                </motion.button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;