import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

interface PricingCardProps {
  title: string;
  price: string;
  features: string[];
  isPopular?: boolean;
  delay?: number;
  subtitle?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  features,
  isPopular = false,
  delay = 0,
  subtitle
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
      className={`relative bg-white rounded-2xl shadow-lg overflow-hidden flex flex-col h-full ${
        isPopular ? 'ring-2 ring-primary' : ''
      }`}
    >
      {isPopular && (
        <div className="absolute top-0 right-0 bg-primary text-white px-4 py-1 text-sm font-medium rounded-bl-lg">
          Beliebt
        </div>
      )}

      <div className="p-8 flex-1 flex flex-col">
        <div className="mb-8">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
          {subtitle && (
            <p className="text-gray-600 text-sm">{subtitle}</p>
          )}
          
          <div className="mt-6">
            <span className="text-4xl font-bold text-primary">{price}</span>
            <span className="text-gray-600">*</span>
          </div>
        </div>

        <ul className="space-y-4 mb-8 flex-1">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-primary flex-shrink-0" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={`w-full py-3 px-6 rounded-lg font-semibold transition-colors ${
            isPopular
              ? 'bg-primary text-white hover:bg-primary/90'
              : 'bg-gray-100 text-primary hover:bg-gray-200'
          }`}
        >
          Anfrage starten
        </motion.button>
      </div>
    </motion.div>
  );
};

export default PricingCard;