import React from 'react';
import { motion } from 'framer-motion';

interface ImpressumSectionProps {
  title: string;
  children: React.ReactNode;
  delay?: number;
}

const ImpressumSection: React.FC<ImpressumSectionProps> = ({ title, children, delay = 0 }) => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      className="mb-8"
    >
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      {children}
    </motion.section>
  );
};

export default ImpressumSection;