import React from 'react';
import { useForm } from './FormContext';
import StepIndicator from './StepIndicator';
import UserCenteredDesign from './steps/UserCenteredDesign';
import WebsiteGoals from './steps/WebsiteGoals';
import Competition from './steps/Competition';
import ElevatorPitch from './steps/ElevatorPitch';
import BuyerPersona from './steps/BuyerPersona';
import Navigation from './steps/Navigation';
import PageReview from './steps/PageReview';

const FormSteps = () => {
  const { currentStep, submitted } = useForm();

  return (
    <div>
      <StepIndicator />
      {submitted ? (
        <div className="mb-6 bg-green-50 p-4 rounded-lg">
          <p className="text-green-700">
            Vielen Dank für Ihre Angaben! Wir haben Ihnen eine Bestätigungs-E-Mail 
            mit einer Zusammenfassung geschickt. Sie können die Daten auch als PDF 
            herunterladen.
          </p>
        </div>
      ) : (
        <>
          {currentStep === 1 && <UserCenteredDesign />}
          {currentStep === 2 && <WebsiteGoals />}
          {currentStep === 3 && <Competition />}
          {currentStep === 4 && <ElevatorPitch />}
          {currentStep === 5 && <BuyerPersona />}
          {currentStep === 6 && <Navigation />}
          {currentStep === 7 && <PageReview />}
        </>
      )}
    </div>
  );
};

export default FormSteps;