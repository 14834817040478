import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const BuyerPersona = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Buyer Persona</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <FormField
          label="Alter der Persona"
          name="personaAge"
          value={formData.personaAge}
          onChange={handleChange}
          placeholder="z.B. 25-35"
          error={errors.personaAge}
          required
        />

        <FormField
          label="Bildungsniveau"
          name="personaEducation"
          value={formData.personaEducation}
          onChange={handleChange}
          placeholder="z.B. Hochschulabschluss"
        />
      </div>

      <FormField
        label="Berufliche Situation"
        name="personaJob"
        value={formData.personaJob}
        onChange={handleChange}
        type="textarea"
        placeholder="Position, Branche, Erfahrung..."
      />

      <FormField
        label="Ziele und Wünsche"
        name="personaGoals"
        value={formData.personaGoals}
        onChange={handleChange}
        type="textarea"
        placeholder="Was möchte diese Person erreichen?"
        error={errors.personaGoals}
        required
      />

      <FormField
        label="Kaufverhalten"
        name="personaBuying"
        value={formData.personaBuying}
        onChange={handleChange}
        type="textarea"
        placeholder="Wie trifft diese Person Kaufentscheidungen?"
      />
    </div>
  );
};

export default BuyerPersona;