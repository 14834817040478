interface RouteValidation {
  path: string;
  exists: boolean;
  isAccessible: boolean;
  errors?: string[];
}

export const validateRoutes = async (routes: string[]): Promise<RouteValidation[]> => {
  const validations: RouteValidation[] = [];

  for (const route of routes) {
    try {
      const response = await fetch(route);
      validations.push({
        path: route,
        exists: response.ok,
        isAccessible: response.status !== 403 && response.status !== 401,
        errors: response.ok ? undefined : [`HTTP ${response.status}: ${response.statusText}`]
      });
    } catch (error) {
      validations.push({
        path: route,
        exists: false,
        isAccessible: false,
        errors: [(error as Error).message]
      });
    }
  }

  return validations;
};

export const validateInternalLinks = (document: Document): string[] => {
  const issues: string[] = [];
  const links = document.querySelectorAll('a[href^="/"], a[href^="#"]');

  links.forEach((link) => {
    const href = link.getAttribute('href');
    if (!href) {
      issues.push('Empty href attribute found');
      return;
    }

    if (href.startsWith('#')) {
      const targetId = href.slice(1);
      const target = document.getElementById(targetId);
      if (!target) {
        issues.push(`Broken anchor link: ${href}`);
      }
    }
  });

  return issues;
};