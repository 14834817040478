import React, { useState } from 'react';
import { motion } from 'framer-motion';
import IndustryGrid from './IndustryGrid';
import IndustryModal from './IndustryModal';
import { industries } from './data/industries';
import { Industry } from './types';

const IndustryShowcase = () => {
  const [selectedIndustry, setSelectedIndustry] = useState<Industry | null>(null);

  return (
    <section className="py-24 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Branchenspezifische Lösungen
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Entdecken Sie unsere maßgeschneiderten Webdesign-Lösungen für Ihre Branche
          </p>
        </motion.div>

        <IndustryGrid 
          industries={industries} 
          onSelect={setSelectedIndustry}
        />

        <IndustryModal 
          industry={selectedIndustry} 
          onClose={() => setSelectedIndustry(null)} 
        />
      </div>
    </section>
  );
};

export default IndustryShowcase;