import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

const ContactInfo = () => {
  return (
    <div className="space-y-3">
      <div className="flex items-start space-x-3">
        <MapPin className="w-5 h-5 text-primary mt-1" />
        <div>
          <p>Richard-Billinger-Weg 2</p>
          <p>4774 Sankt Marienkirchen bei Schärding</p>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <Phone className="w-5 h-5 text-primary" />
        <a href="tel:+43720317724" className="hover:text-primary transition-colors">
          +43(0)720-317724
        </a>
      </div>
      <div className="flex items-center space-x-3">
        <Mail className="w-5 h-5 text-primary" />
        <a href="mailto:office@webdesign.or.at" className="hover:text-primary transition-colors">
          office@webdesign.or.at
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;