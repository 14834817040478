import React from 'react';
import { Scale } from 'lucide-react';

const DisputeResolution = () => {
  return (
    <div className="space-y-4">
      <div className="flex items-start space-x-3">
        <Scale className="w-5 h-5 text-primary mt-1 flex-shrink-0" />
        <p className="text-gray-700">
          Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO 
          niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf 
          von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG) 
          zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit:{' '}
          <a 
            href="https://ec.europa.eu/consumers/odr" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-primary hover:underline"
          >
            https://ec.europa.eu/consumers/odr
          </a>
        </p>
      </div>
    </div>
  );
};

export default DisputeResolution;