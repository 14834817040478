import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface BackgroundProps {
  imageUrl: string;
  width: number;
  height: number;
}

const Background: React.FC<BackgroundProps> = ({ imageUrl, width, height }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    
    img.onload = () => {
      setIsLoading(false);
      setError(null);
    };
    
    img.onerror = () => {
      setIsLoading(false);
      setError('Failed to load image');
    };
  }, [imageUrl]);

  if (error) {
    console.error('Background image error:', error);
    return (
      <div className="absolute inset-0 bg-gradient-to-r from-primary-700 to-primary-900" />
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="absolute inset-0"
    >
      <img
        src={imageUrl}
        alt=""
        className="w-full h-full object-cover"
        loading="eager"
        width={width}
        height={height}
        onLoad={() => setIsLoading(false)}
        style={{ 
          opacity: isLoading ? 0 : 1,
          transition: 'opacity 0.3s ease-in-out'
        }}
      />
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
    </motion.div>
  );
};

export default Background;