export const generatePDF = (data: Record<string, any>) => {
  const content = Object.entries(data)
    .map(([key, value]) => {
      return `${key}: ${value}\n`;
    })
    .join('\n');
  
  const blob = new Blob([content], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'onboarding-daten.pdf';
  link.click();
  URL.revokeObjectURL(url);
};