import React from 'react';
import { motion } from 'framer-motion';
import { ArrowDown } from 'lucide-react';

const ScrollIndicator = () => {
  return (
    <motion.a
      href="#services"
      animate={{ y: [0, 10, 0] }}
      transition={{ repeat: Infinity, duration: 1.5 }}
      className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white cursor-pointer"
      aria-label="Zu unseren Services scrollen"
    >
      <ArrowDown size={32} />
    </motion.a>
  );
};

export default ScrollIndicator;