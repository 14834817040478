import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, FileText, UserCheck } from 'lucide-react';

const Datenschutz = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4 max-w-4xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center space-x-3 mb-8">
            <Shield className="w-8 h-8 text-primary" />
            <h1 className="text-4xl font-bold">Datenschutzerklärung</h1>
          </div>

          <div className="prose prose-lg max-w-none">
            <div className="bg-gray-50 p-6 rounded-xl mb-8">
              <p className="text-gray-700">
                In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der 
                Datenverarbeitung im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene 
                Daten nur auf Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung, 
                Telekommunikationsgesetz 2003).
              </p>
            </div>

            <div className="space-y-8">
              <section>
                <div className="flex items-center space-x-2 mb-4">
                  <Lock className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Zugriffsdaten</h2>
                </div>
                <p>
                  Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre 
                  IP-Adresse, Beginn sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt 
                  und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
                </p>
              </section>

              <section>
                <div className="flex items-center space-x-2 mb-4">
                  <FileText className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Kontakt mit uns</h2>
                </div>
                <p>
                  Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email 
                  kontaktieren, dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung 
                  Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate bei uns 
                  gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.
                </p>
              </section>

              <section>
                <div className="flex items-center space-x-2 mb-4">
                  <Lock className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Cookies</h2>
                </div>
                <p>
                  Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, 
                  die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. 
                  Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben 
                  auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser 
                  beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser 
                  so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall 
                  erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                </p>
              </section>

              <section>
                <div className="flex items-center space-x-2 mb-4">
                  <UserCheck className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Ihre Rechte als Betroffener</h2>
                </div>
                <p>
                  Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Auskunft</li>
                  <li>Löschung der Daten</li>
                  <li>Berichtigung der Daten</li>
                  <li>Übertragbarkeit der Daten</li>
                  <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
                  <li>Einschränkung</li>
                </ul>
                <p className="mt-4">
                  Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht 
                  passiert sind, so haben Sie die Möglichkeit sich bei uns (office@webdesign.or.at) oder der 
                  Datenschutzbehörde zu beschweren.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Kontaktdaten</h2>
                <p>
                  <strong>Webseitenbetreiber:</strong> webdesign.or.at - Andreas Höfler<br />
                  <strong>Telefon:</strong> +43 660 6666 370<br />
                  <strong>E-Mail:</strong> office@webdesign.or.at
                </p>
                <p className="text-sm text-gray-500 mt-4">
                  Quelle: Datenschutzgenerator Österreich DSGVO
                </p>
              </section>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Datenschutz;