import React from 'react';
import { Scissors } from 'lucide-react';
import HeroSection from '../shared/HeroSection';
import ServicesSection from './ServicesSection';
import TeamSection from './TeamSection';
import GallerySection from './GallerySection';
import ContactSection from '../shared/ContactSection';

export const BarbershopExample: React.FC = () => {
  return (
    <div className="min-h-screen">
      <HeroSection
        title="Classic Cuts"
        subtitle="Premium Barbershop seit 1995"
        backgroundImage="https://images.unsplash.com/photo-1503951914875-452162b0f3f1?auto=format&fit=crop&q=80"
        ctaText="Termin buchen"
        icon={<Scissors className="w-8 h-8 text-white" />}
      />
      <ServicesSection />
      <TeamSection />
      <GallerySection />
      <ContactSection
        phone="+43 1 234 5678"
        email="info@classiccuts.at"
        address="Hauptstraße 123, 1010 Wien"
      />
    </div>
  );
};