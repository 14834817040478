import React from 'react';
import { motion } from 'framer-motion';

const HeroCallToAction = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8, delay: 0.4 }}
      className="lg:col-span-2 flex justify-center"
    >
      <div className="w-full max-w-2xl">
        <motion.div 
          className="relative bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-8"
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <h2 className="text-2xl md:text-3xl text-white text-center font-bold mb-6">
            Ihre Vision. Unsere Expertise.
          </h2>
          <div className="flex justify-center">
            <motion.a
              href="#contact"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block bg-white text-primary px-8 py-4 rounded-full font-semibold shadow-lg hover:shadow-xl transition-all"
            >
              Kostenloses Erstgespräch sichern
            </motion.a>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default HeroCallToAction;