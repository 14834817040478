import React from 'react';
import { Download, Save } from 'lucide-react';
import { useForm } from './FormContext';
import { generatePDF } from '../../utils/pdf';

const FormHeader = () => {
  const { formData, saveStatus } = useForm();

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Webdesign Onboarding</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => generatePDF(formData)}
            className="flex items-center text-sm text-blue-600 hover:text-blue-700"
          >
            <Download className="w-4 h-4 mr-1" />
            Als PDF speichern
          </button>
          {saveStatus && (
            <div className="flex items-center text-sm text-green-600">
              <Save className="w-4 h-4 mr-1" />
              {saveStatus}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormHeader;