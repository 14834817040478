import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const PageReview = () => {
  const { formData, setFormData, errors, setFiles } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const name = e.target.name;
    
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setFormData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            [name]: 'Die Datei ist zu groß (max. 5MB)'
          }
        }));
        return;
      }

      setFiles(prev => ({
        ...prev,
        [name]: file
      }));
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Seiten-Review</h3>
      
      <FormField
        label="Welche bestehenden Inhalte sollen übernommen werden?"
        name="currentContent"
        value={formData.currentContent}
        onChange={handleChange}
        type="textarea"
        placeholder="Welche Inhalte sind noch aktuell?"
        error={errors.currentContent}
        required
      />
      
      <FormField
        label="Welche Inhalte müssen aktualisiert werden?"
        name="contentToUpdate"
        value={formData.contentToUpdate}
        onChange={handleChange}
        type="textarea"
        placeholder="Was muss überarbeitet werden?"
      />

      <FormField
        label="SEO-Status der aktuellen Website"
        name="seoStatus"
        value={formData.seoStatus}
        onChange={handleChange}
        type="textarea"
        placeholder="Wie ist die aktuelle SEO-Performance?"
      />

      <FormField
        label="Relevante Dokumente (max. 5MB)"
        name="documents"
        type="file"
        onChange={handleFileChange}
        error={errors.documents}
      />

      <FormField
        label="Wichtige Analytics-Daten"
        name="analyticsData"
        value={formData.analyticsData}
        onChange={handleChange}
        type="textarea"
        placeholder="Relevante Besucherdaten und Conversion-Raten..."
      />
    </div>
  );
};

export default PageReview;