import React from 'react';
import { motion } from 'framer-motion';

interface NavigationItemProps {
  href: string;
  children: React.ReactNode;
  isActive: boolean;
  internal?: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ 
  href, 
  children, 
  isActive,
  internal = true
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (internal && href.startsWith('/#')) {
      e.preventDefault();
      const element = document.querySelector(href.replace('/#', '#'));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <li>
      <a
        href={href}
        className={`relative px-3 py-2 text-base transition-colors ${
          isActive ? 'text-primary' : 'text-gray-600 hover:text-primary'
        }`}
        onClick={handleClick}
        {...(!internal && {
          target: "_blank",
          rel: "noopener noreferrer"
        })}
      >
        {children}
        {isActive && (
          <motion.div
            layoutId="activeIndicator"
            className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
            initial={false}
            transition={{ type: "spring", stiffness: 380, damping: 30 }}
          />
        )}
      </a>
    </li>
  );
};

export default NavigationItem;