import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import Rotate3D from './effects/Rotate3D';

const promises = [
  'Transparente Kommunikation während des gesamten Projekts',
  'Regelmäßige Updates und Abstimmungsmeetings',
  'Einhaltung vereinbarter Timelines',
  'Kostenlose Nachbetreuung nach Launch'
];

const QualityPromise = () => {
  return (
    <div className="bg-white rounded-2xl p-8 shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
      <h3 className="text-2xl font-bold mb-6">Unser Qualitätsversprechen</h3>
      <div className="space-y-4">
        {promises.map((promise, index) => (
          <Rotate3D key={index}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="flex items-start space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <div className="w-5 h-5 bg-primary/10 rounded-full flex items-center justify-center flex-shrink-0 mt-1">
                <Check className="w-3 h-3 text-primary" />
              </div>
              <span className="text-gray-700">{promise}</span>
            </motion.div>
          </Rotate3D>
        ))}
      </div>
    </div>
  );
};

export default QualityPromise;