import { useState, useEffect } from 'react';

export const useScrollSpy = (sectionIds: string[], offset = 100) => {
  const [activeSection, setActiveSection] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionIds
        .map(id => {
          const element = document.getElementById(id);
          if (element) {
            return {
              id,
              offsetTop: element.offsetTop,
              offsetHeight: element.offsetHeight
            };
          }
          return null;
        })
        .filter((section): section is NonNullable<typeof section> => section !== null);

      const scrollPosition = window.scrollY + offset;

      for (const section of sections) {
        if (
          scrollPosition >= section.offsetTop &&
          scrollPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          return;
        }
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Check initial position

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionIds, offset]);

  return activeSection;
};