import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Phone, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <img 
              src="https://r2.hoefler-sbk.de/Bilder_Webseite/Webdesign%20(1).png"
              alt="Höfler Webdesign Logo"
              className="h-16"
            />
            <p className="text-gray-400">
              Professionelle Webentwicklung und digitale Lösungen für Ihren Geschäftserfolg.
            </p>
            <div className="flex items-center space-x-4">
              <motion.a
                href="mailto:office@webdesign.or.at"
                whileHover={{ scale: 1.1 }}
                className="bg-white/10 p-2 rounded-lg hover:bg-primary transition-colors"
              >
                <Mail className="w-5 h-5" />
              </motion.a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-6">Navigation</h3>
            <ul className="space-y-4">
              <li>
                <a href="/#services" className="text-gray-400 hover:text-primary transition-colors">
                  Leistungen
                </a>
              </li>
              <li>
                <a href="/#industries" className="text-gray-400 hover:text-primary transition-colors">
                  Branchen
                </a>
              </li>
              <li>
                <a href="/#process" className="text-gray-400 hover:text-primary transition-colors">
                  Ablauf
                </a>
              </li>
              <li>
                <a href="/#contact" className="text-gray-400 hover:text-primary transition-colors">
                  Kontakt
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-bold mb-6">Kontakt</h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3 text-gray-400">
                <MapPin className="w-5 h-5 text-primary" />
                <span>Richard-Billinger-Weg 2<br />4774 Sankt Marienkirchen</span>
              </li>
              <li>
                <a href="tel:+43720317724" className="flex items-center space-x-3 text-gray-400 hover:text-primary transition-colors">
                  <Phone className="w-5 h-5 text-primary" />
                  <span>+43(0)720-317724</span>
                </a>
              </li>
              <li>
                <a href="mailto:office@webdesign.or.at" className="flex items-center space-x-3 text-gray-400 hover:text-primary transition-colors">
                  <Mail className="w-5 h-5 text-primary" />
                  <span>office@webdesign.or.at</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Partners */}
          <div>
            <h3 className="text-lg font-bold mb-6">Partner</h3>
            <div className="space-y-6">
              <div className="flex flex-col space-y-6">
                <a 
                  href="https://hetzner.cloud/?ref=scyvnLLWLtnT"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block transition-opacity hover:opacity-100 opacity-80 p-4 h-[60px] flex items-center justify-center"
                >
                  <img 
                    src="https://r2.hoefler-sbk.de/Bilder_Webseite/hetzner.png" 
                    alt="Hetzner Cloud Partner" 
                    className="h-full w-auto object-contain"
                  />
                </a>
                <a 
                  href="https://hoefler-it.at/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block transition-opacity hover:opacity-100 opacity-80 p-4 h-[60px] flex items-center justify-center"
                >
                  <img 
                    src="https://r2.hoefler-sbk.de/Bilder_Webseite/it_dark.png" 
                    alt="Höfler IT Partner" 
                    className="h-full w-auto object-contain"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-gray-400 text-sm">
              © {currentYear} Höfler Webdesign. Alle Rechte vorbehalten.
            </p>
            <div className="flex space-x-6">
              <Link to="/datenschutz" className="text-gray-400 hover:text-primary text-sm transition-colors">
                Datenschutz
              </Link>
              <Link to="/impressum" className="text-gray-400 hover:text-primary text-sm transition-colors">
                Impressum
              </Link>
              <Link to="/agb" className="text-gray-400 hover:text-primary text-sm transition-colors">
                AGB
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;