import { ContactFormData } from '../types';

const BREVO_API_KEY = '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';
const BREVO_API_URL = 'https://api.brevo.com/v3/smtp/email';

export const sendContactForm = async (formData: ContactFormData): Promise<boolean> => {
  try {
    const response = await fetch(BREVO_API_URL, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'api-key': BREVO_API_KEY,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        sender: {
          name: formData.name,
          email: 'kontaktformular@webdesign.or.at'
        },
        to: [{
          email: 'office@webdesign.or.at',
          name: 'Höfler Webdesign'
        }],
        subject: `Neue Kontaktanfrage von ${formData.name}`,
        htmlContent: `
          <h2>Neue Kontaktanfrage</h2>
          <p><strong>Firma:</strong> ${formData.company}</p>
          <p><strong>Name:</strong> ${formData.name}</p>
          <p><strong>Telefon:</strong> ${formData.phone}</p>
          <p><strong>E-Mail:</strong> ${formData.email}</p>
          <p><strong>Website:</strong> ${formData.website || 'Nicht angegeben'}</p>
          <p><strong>Gewünschte Leistungen:</strong></p>
          <ul>
            ${Object.entries(formData.services)
              .filter(([_, value]) => value)
              .map(([key]) => `<li>${key}</li>`)
              .join('')}
          </ul>
          <p><strong>Art der Anfrage:</strong> ${formData.requestType}</p>
          <p><strong>Nachricht:</strong></p>
          <p>${formData.message}</p>
        `
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    return true;
  } catch (error) {
    console.error('Error sending contact form:', error);
    return false;
  }
};