import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Truck, CreditCard, Package } from 'lucide-react';

export const RetailExample: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      {/* Hero Section */}
      <div className="relative h-screen">
        <img 
          src="https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80"
          alt="Retail Store"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50" />
        
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="max-w-4xl px-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6">
                ModaStyle Shop
              </h1>
              <p className="text-xl md:text-2xl mb-8">
                Ihr Online-Shop für Mode & Lifestyle
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-primary text-white px-8 py-3 rounded-lg font-semibold text-lg"
                >
                  Zum Shop
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white text-primary px-8 py-3 rounded-lg font-semibold text-lg"
                >
                  Neue Kollektion
                </motion.button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Ihre Vorteile</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              {
                icon: <ShoppingBag className="w-8 h-8" />,
                title: "Click & Collect",
                description: "Online bestellen, lokal abholen"
              },
              {
                icon: <Truck className="w-8 h-8" />,
                title: "Kostenloser Versand",
                description: "Ab einem Bestellwert von €50"
              },
              {
                icon: <CreditCard className="w-8 h-8" />,
                title: "Sichere Zahlung",
                description: "Verschiedene Zahlungsmöglichkeiten"
              },
              {
                icon: <Package className="w-8 h-8" />,
                title: "30 Tage Rückgabe",
                description: "Kostenlose Retoure"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-gray-50 p-8 rounded-xl text-center"
              >
                <div className="text-primary mb-4 flex justify-center">{feature.icon}</div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RetailExample;