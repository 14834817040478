import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const ElevatorPitch = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Elevator Pitch</h3>
      
      <FormField
        label="Was ist der Kernnutzen Ihres Angebots?"
        name="coreBenefit"
        value={formData.coreBenefit}
        onChange={handleChange}
        type="textarea"
        placeholder="Beschreiben Sie den Hauptnutzen in einem Satz..."
        error={errors.coreBenefit}
        required
      />
      
      <FormField
        label="Für wen ist Ihr Angebot gedacht?"
        name="targetGroup"
        value={formData.targetGroup}
        onChange={handleChange}
        type="textarea"
        placeholder="Beschreiben Sie Ihre ideale Zielgruppe..."
        error={errors.targetGroup}
        required
      />
      
      <FormField
        label="Was macht Ihr Angebot einzigartig?"
        name="uniqueFeature"
        value={formData.uniqueFeature}
        onChange={handleChange}
        type="textarea"
        placeholder="Ihr wichtigstes Alleinstellungsmerkmal..."
      />

      <FormField
        label="Zusammenfassung in max. 30 Worten"
        name="pitchSummary"
        value={formData.pitchSummary}
        onChange={handleChange}
        type="textarea"
        placeholder="Ihre Kernbotschaft kurz und knackig..."
        rows={2}
      />
    </div>
  );
};

export default ElevatorPitch;