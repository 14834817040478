import React from 'react';
import { motion } from 'framer-motion';
import { Users, Wifi, Coffee, Tv } from 'lucide-react';

const rooms = [
  {
    title: 'Komfort Zimmer',
    price: '€120',
    image: 'https://images.unsplash.com/photo-1566665797739-1674de7a421a?auto=format&fit=crop&q=80',
    features: ['2 Personen', 'WLAN', 'Frühstück', 'Smart TV']
  },
  {
    title: 'Deluxe Suite',
    price: '€220',
    image: 'https://images.unsplash.com/photo-1590490360182-c33d57733427?auto=format&fit=crop&q=80',
    features: ['4 Personen', 'WLAN', 'Frühstück', 'Smart TV']
  }
];

const featureIcons = {
  'WLAN': Wifi,
  'Frühstück': Coffee,
  'Smart TV': Tv,
  '2 Personen': Users,
  '4 Personen': Users
};

const RoomSection: React.FC = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Unsere Zimmer</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {rooms.map((room, index) => (
            <motion.div
              key={room.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div className="relative h-64">
                <img
                  src={room.image}
                  alt={room.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold">{room.title}</h3>
                  <span className="text-2xl font-bold text-primary">{room.price}</span>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {room.features.map((feature) => {
                    const Icon = featureIcons[feature as keyof typeof featureIcons];
                    return (
                      <div key={feature} className="flex items-center gap-2">
                        <Icon className="w-5 h-5 text-primary" />
                        <span>{feature}</span>
                      </div>
                    );
                  })}
                </div>
                <button className="w-full mt-6 bg-primary text-white py-2 rounded-lg font-semibold hover:bg-primary/90 transition-colors">
                  Verfügbarkeit prüfen
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoomSection;