import React from 'react';
import { motion } from 'framer-motion';
import { Heart, Calendar, Clock, Phone, Star } from 'lucide-react';

export const MedicalExample: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      {/* Hero Section */}
      <div className="relative h-screen">
        <img 
          src="https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&q=80"
          alt="Medical Practice"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50" />
        
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="max-w-4xl px-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6">
                Ordination Dr. Müller
              </h1>
              <p className="text-xl md:text-2xl mb-8">
                Ihre Gesundheit in besten Händen
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-primary text-white px-8 py-3 rounded-lg font-semibold text-lg inline-flex items-center justify-center gap-2"
                >
                  <Calendar className="w-5 h-5" />
                  Termin vereinbaren
                </motion.button>
                <motion.a
                  href="tel:+43123456789"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white text-primary px-8 py-3 rounded-lg font-semibold text-lg inline-flex items-center justify-center gap-2"
                >
                  <Phone className="w-5 h-5" />
                  Notfallnummer
                </motion.a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Unsere Leistungen</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <Heart className="w-8 h-8" />,
                title: "Allgemeinmedizin",
                description: "Umfassende medizinische Grundversorgung"
              },
              {
                icon: <Calendar className="w-8 h-8" />,
                title: "Vorsorgeuntersuchung",
                description: "Präventive Gesundheitsvorsorge"
              },
              {
                icon: <Clock className="w-8 h-8" />,
                title: "Akutversorgung",
                description: "Schnelle Hilfe bei akuten Beschwerden"
              }
            ].map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-gray-50 p-8 rounded-xl"
              >
                <div className="text-primary mb-4">{service.icon}</div>
                <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MedicalExample;