import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Phone, MapPin } from 'lucide-react';
import ContactForm from './Contact/ContactForm';

const Contact = () => {
  return (
    <section id="contact" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Kontakt</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="space-y-8"
          >
            <h3 className="text-2xl font-bold mb-6">Sprechen Sie mit uns</h3>
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <MapPin className="text-primary w-6 h-6" />
                </div>
                <div>
                  <p className="font-medium text-gray-700">Adresse</p>
                  <p className="text-gray-600">Richard-Billinger-Weg 2</p>
                  <p className="text-gray-600">4774 Sankt Marienkirchen bei Schärding</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <Phone className="text-primary w-6 h-6" />
                </div>
                <div>
                  <p className="font-medium text-gray-700">Telefon</p>
                  <a 
                    href="tel:+43720317724" 
                    className="text-gray-600 hover:text-primary transition-colors"
                  >
                    +43(0)720-317724
                  </a>
                </div>
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <Mail className="text-primary w-6 h-6" />
                </div>
                <div>
                  <p className="font-medium text-gray-700">E-Mail</p>
                  <a 
                    href="mailto:office@webdesign.or.at"
                    className="text-gray-600 hover:text-primary transition-colors"
                  >
                    office@webdesign.or.at
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <iframe
                title="Standort"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.1374149425387!2d13.4463!3d48.2947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4774528aa0aa6f3d%3A0x86f283a2d3bc3a89!2sRichard-Billinger-Weg%202%2C%204774%20Sankt%20Marienkirchen%20bei%20Sch%C3%A4rding!5e0!3m2!1sde!2sat!4v1234567890"
                width="100%"
                height="250"
                style={{ border: 0, borderRadius: '0.5rem' }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="shadow-md"
              />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-white rounded-xl shadow-lg p-8"
          >
            <h3 className="text-2xl font-bold mb-6">Nachricht senden</h3>
            <ContactForm />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;