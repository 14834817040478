import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Home, Search, ArrowLeft, Compass } from 'lucide-react';

const NotFound = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      window.location.href = '/';
    }
  }, [countdown]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement search functionality here
    console.log('Searching for:', searchQuery);
  };

  return (
    <section className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="max-w-2xl w-full bg-white rounded-2xl shadow-xl p-8 md:p-12"
      >
        <div className="text-center mb-8">
          <motion.div
            animate={{ rotate: [0, 10, -10, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
            className="inline-block"
          >
            <Compass className="w-24 h-24 text-primary mb-6" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-4">
            Oops! Seite nicht gefunden
          </h1>
          <p className="text-gray-600 text-lg mb-2">
            Leider konnten wir die gesuchte Seite nicht finden.
          </p>
          <p className="text-sm text-gray-500">
            Error 404 - Die angeforderte Seite existiert nicht oder wurde verschoben.
          </p>
        </div>

        <div className="space-y-6">
          <form onSubmit={handleSearch} className="relative">
            <input
              type="text"
              placeholder="Suchen Sie nach Inhalten..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
              aria-label="Suchfeld"
            />
            <button
              type="submit"
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-primary transition-colors"
              aria-label="Suchen"
            >
              <Search className="w-5 h-5" />
            </button>
          </form>

          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <motion.a
              href="/"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="flex items-center justify-center gap-2 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
            >
              <Home className="w-5 h-5" />
              <span>Zur Startseite</span>
            </motion.a>
            
            <motion.button
              onClick={() => window.history.back()}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="flex items-center justify-center gap-2 px-6 py-3 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span>Zurück</span>
            </motion.button>
          </div>
        </div>

        <div className="mt-8 text-center text-sm text-gray-500">
          <p>Sie werden in {countdown} Sekunden zur Startseite weitergeleitet</p>
        </div>

        {/* Accessibility enhancements */}
        <div className="sr-only" role="status" aria-live="polite">
          404 Fehlerseite - Seite nicht gefunden
        </div>
      </motion.div>
    </section>
  );
};

export default NotFound;