import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const VALID_TOKENS = ['demo123']; // In production, this would be managed securely

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { token } = useParams<{ token: string }>();
  
  if (!token || !VALID_TOKENS.includes(token)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;