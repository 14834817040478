import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, PenTool, Palette, Code, TestTube, Rocket } from 'lucide-react';
import ProcessStep from './ProcessStep';
import QualityPromise from './QualityPromise';

const processSteps = [
  {
    number: '01',
    title: 'Erstgespräch',
    description: 'Kostenlose Beratung zur Ermittlung Ihrer spezifischen Anforderungen und Ziele.',
    icon: MessageSquare
  },
  {
    number: '02',
    title: 'Konzeption',
    description: 'Ausarbeitung eines detaillierten Projektplans inkl. Sitemap und Funktionsumfang.',
    icon: PenTool
  },
  {
    number: '03',
    title: 'Design',
    description: 'Erstellung des visuellen Designs unter Berücksichtigung Ihrer CI und Zielgruppe.',
    icon: Palette
  },
  {
    number: '04',
    title: 'Entwicklung',
    description: 'Professionelle Umsetzung mit modernsten Technologien für optimale Performance.',
    icon: Code
  },
  {
    number: '05',
    title: 'Testing',
    description: 'Umfangreiche Tests auf allen Geräten und Browsern für fehlerfreie Funktion.',
    icon: TestTube
  },
  {
    number: '06',
    title: 'Launch',
    description: 'Professionelle Inbetriebnahme inkl. SEO-Optimierung und Analytics-Setup.',
    icon: Rocket
  }
];

const Process = () => {
  return (
    <section id="process" className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6">
            Ablauf Webdesign
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Transparenter Ablauf von der ersten Idee bis zum erfolgreichen Launch
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-16">
          {processSteps.map((step, index) => (
            <ProcessStep
              key={step.number}
              {...step}
              delay={index * 0.1}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          <QualityPromise />
        </motion.div>
      </div>
    </section>
  );
};

export default Process;