import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const WebsiteGoals = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Website-Ziele</h3>
      
      <FormField
        label="Was sind Ihre wichtigsten Geschäftsziele?"
        name="businessGoals"
        value={formData.businessGoals}
        onChange={handleChange}
        type="textarea"
        placeholder="Beschreiben Sie Ihre konkreten Geschäftsziele..."
        error={errors.businessGoals}
        required
      />
      
      <FormField
        label="Welche Marketing-Ziele verfolgen Sie?"
        name="marketingGoals"
        value={formData.marketingGoals}
        onChange={handleChange}
        type="textarea"
        placeholder="z.B. Lead-Generierung, Markenbekanntheit..."
        error={errors.marketingGoals}
        required
      />
      
      <FormField
        label="Messbare Ziele"
        name="measurableObjectives"
        value={formData.measurableObjectives}
        onChange={handleChange}
        type="textarea"
        placeholder="Welche konkreten KPIs möchten Sie erreichen?"
      />
    </div>
  );
};

export default WebsiteGoals;