export const validateField = (name: string, value: string): string => {
  if (!value || value.trim() === '') {
    return 'Dieses Feld wird benötigt';
  }
  if (name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
  }
  return '';
};

export const validateStep = (step: number, formData: Record<string, any>): Record<string, string> => {
  const stepErrors: Record<string, string> = {};
  const requiredFields = getRequiredFieldsForStep(step);
  
  requiredFields.forEach(field => {
    const error = validateField(field, formData[field]);
    if (error) {
      stepErrors[field] = error;
    }
  });
  
  return stepErrors;
};

export const getRequiredFieldsForStep = (step: number): string[] => {
  switch (step) {
    case 1:
      return ['targetAudience', 'userNeeds'];
    case 2:
      return ['businessGoals', 'marketingGoals'];
    case 3:
      return ['mainCompetitors', 'uniqueAdvantage'];
    case 4:
      return ['coreBenefit', 'targetGroup'];
    case 5:
      return ['personaAge', 'personaGoals'];
    case 6:
      return ['mainSections'];
    case 7:
      return ['currentContent'];
    default:
      return [];
  }
};