import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import Tilt3D from './effects/Tilt3D';
import ParallaxContainer from './effects/ParallaxContainer';

interface ProcessStepProps {
  number: string;
  title: string;
  description: string;
  icon: LucideIcon;
  delay?: number;
}

const ProcessStep: React.FC<ProcessStepProps> = ({ 
  number, 
  title, 
  description, 
  icon: Icon, 
  delay = 0 
}) => {
  return (
    <ParallaxContainer offset={20}>
      <Tilt3D>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay }}
          viewport={{ once: true }}
          className="relative flex flex-col items-center text-center bg-white p-6 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
        >
          <div className="relative">
            <div className="w-16 h-16 bg-primary/10 rounded-2xl flex items-center justify-center mb-4">
              <Icon className="w-8 h-8 text-primary" />
            </div>
            <div className="absolute -top-2 -right-2 w-6 h-6 bg-primary rounded-full flex items-center justify-center text-white text-sm font-bold">
              {number}
            </div>
          </div>
          <h3 className="text-xl font-bold mb-2">{title}</h3>
          <p className="text-gray-600 max-w-sm">{description}</p>
        </motion.div>
      </Tilt3D>
    </ParallaxContainer>
  );
};

export default ProcessStep;