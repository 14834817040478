import React from 'react';
import IndustryCard from './IndustryCard';
import { Industry } from './types';

interface IndustryGridProps {
  industries: Industry[];
  onSelect: (industry: Industry) => void;
}

const IndustryGrid: React.FC<IndustryGridProps> = ({ industries, onSelect }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
      {industries.map((industry) => (
        <IndustryCard 
          key={industry.id} 
          industry={industry} 
          onClick={() => onSelect(industry)}
        />
      ))}
    </div>
  );
};

export default IndustryGrid;