import React from 'react';
import { motion } from 'framer-motion';
import { Calendar } from 'lucide-react';

const HeroSection: React.FC = () => {
  return (
    <div className="relative h-screen">
      <img 
        src="https://images.unsplash.com/photo-1566073771259-6a8506099945?auto=format&fit=crop&q=80"
        alt="Luxury Hotel"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black/40" />
      
      <div className="absolute inset-0 flex items-center justify-center text-white">
        <div className="max-w-4xl px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-6">
              Hotel Alpenpanorama
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Luxuriöser Aufenthalt mit atemberaubendem Bergblick
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white px-8 py-3 rounded-lg font-semibold text-lg inline-flex items-center gap-2"
            >
              <Calendar className="w-5 h-5" />
              Jetzt buchen
            </motion.button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;