import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface Tilt3DProps {
  children: React.ReactNode;
  scale?: number;
  perspective?: number;
}

const Tilt3D: React.FC<Tilt3DProps> = ({ 
  children, 
  scale = 1.02, 
  perspective = 1000 
}) => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientY - rect.top) / rect.height - 0.5;
    const y = (e.clientX - rect.left) / rect.width - 0.5;
    
    setRotation({
      x: x * 20, // Rotation angle in degrees
      y: y * 20
    });
  };

  const handleMouseLeave = () => setRotation({ x: 0, y: 0 });

  return (
    <motion.div
      style={{
        perspective: perspective,
        transformStyle: "preserve-3d"
      }}
      animate={{
        rotateX: -rotation.x,
        rotateY: rotation.y,
        scale: 1
      }}
      whileHover={{ scale }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      transition={{ type: "spring", stiffness: 400, damping: 30 }}
    >
      {children}
    </motion.div>
  );
};

export default Tilt3D;