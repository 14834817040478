import React from 'react';
import { motion } from 'framer-motion';
import { Waves, Heart, Utensils, Dumbbell, Car, Wifi } from 'lucide-react';

const amenities = [
  { icon: Waves, title: 'Infinity Pool', description: 'Mit Panoramablick' },
  { icon: Heart, title: 'Wellness', description: 'Sauna & Massage' },
  { icon: Utensils, title: 'Restaurant', description: 'Regionale Küche' },
  { icon: Dumbbell, title: 'Fitness', description: '24/7 geöffnet' },
  { icon: Car, title: 'Parkplatz', description: 'Kostenlos' },
  { icon: Wifi, title: 'WLAN', description: 'Highspeed' }
];

const AmenitiesSection: React.FC = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Hotelausstattung</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {amenities.map((amenity, index) => (
            <motion.div
              key={amenity.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <amenity.icon className="w-8 h-8 text-primary mb-4" />
              <h3 className="text-xl font-bold mb-2">{amenity.title}</h3>
              <p className="text-gray-600">{amenity.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AmenitiesSection;