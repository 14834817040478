import { useEffect } from 'react';

interface PerformanceMetrics {
  FCP: number;
  LCP: number;
  CLS: number;
  FID: number;
}

export const measureWebVitals = (): Promise<PerformanceMetrics> => {
  return new Promise((resolve) => {
    let metrics: Partial<PerformanceMetrics> = {};

    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      entries.forEach((entry) => {
        if (entry.name === 'first-contentful-paint') {
          metrics.FCP = entry.startTime;
        }
      });
    }).observe({ entryTypes: ['paint'] });

    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      entries.forEach((entry: any) => {
        if (entry.entryType === 'largest-contentful-paint') {
          metrics.LCP = entry.startTime;
        }
      });
    }).observe({ entryTypes: ['largest-contentful-paint'] });

    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      entries.forEach((entry: any) => {
        if (entry.entryType === 'layout-shift') {
          metrics.CLS = (metrics.CLS || 0) + entry.value;
        }
      });
    }).observe({ entryTypes: ['layout-shift'] });

    new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries();
      entries.forEach((entry: any) => {
        if (entry.entryType === 'first-input') {
          metrics.FID = entry.processingStart - entry.startTime;
        }
      });
    }).observe({ entryTypes: ['first-input'] });

    // Resolve after 10 seconds or when all metrics are collected
    setTimeout(() => {
      resolve(metrics as PerformanceMetrics);
    }, 10000);
  });
};

export const usePerformanceMonitoring = () => {
  useEffect(() => {
    measureWebVitals().then((metrics) => {
      console.log('Performance Metrics:', metrics);
      // Here you would typically send these metrics to your analytics service
    });
  }, []);
};