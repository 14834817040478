import React from 'react';
import { motion } from 'framer-motion';

interface Rotate3DProps {
  children: React.ReactNode;
}

const Rotate3D: React.FC<Rotate3DProps> = ({ children }) => {
  return (
    <motion.div
      whileHover={{ 
        rotateY: 180,
        transition: { duration: 0.6, ease: "easeInOut" }
      }}
      style={{ 
        transformStyle: "preserve-3d",
        perspective: 1000
      }}
    >
      <motion.div
        style={{
          backfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden"
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Rotate3D;