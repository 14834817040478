import React from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider } from './FormContext';
import FormSteps from './FormSteps';
import FormNavigation from './FormNavigation';
import FormHeader from './FormHeader';
import PrivateRoute from './PrivateRoute';
import { Alert, AlertDescription } from '../ui/Alert';

const OnboardingForm = () => {
  const { token } = useParams<{ token: string }>();

  return (
    <PrivateRoute>
      <FormProvider>
        <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg my-8">
          <FormHeader />
          <FormSteps />
          <FormNavigation />
        </div>
      </FormProvider>
    </PrivateRoute>
  );
};

export default OnboardingForm;