import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Settings, Shield } from 'lucide-react';
import { CookieSettings, CookieConsent } from '../types/seo';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [consent, setConsent] = useState<CookieConsent>({
    necessary: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    const savedSettings = localStorage.getItem('cookieConsent');
    if (!savedSettings) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const settings: CookieSettings = {
      accepted: true,
      timestamp: Date.now(),
      preferences: {
        necessary: true,
        analytics: true,
        marketing: true
      }
    };
    localStorage.setItem('cookieConsent', JSON.stringify(settings));
    setShowBanner(false);
  };

  const handleSaveSettings = () => {
    const settings: CookieSettings = {
      accepted: true,
      timestamp: Date.now(),
      preferences: consent
    };
    localStorage.setItem('cookieConsent', JSON.stringify(settings));
    setShowBanner(false);
    setShowSettings(false);
  };

  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-lg"
        >
          <div className="container mx-auto px-4 py-6">
            {!showSettings ? (
              <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                <div className="flex items-center space-x-3">
                  <Shield className="w-6 h-6 text-primary" />
                  <p className="text-sm text-gray-600">
                    Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern.
                    Lesen Sie unsere <a href="#datenschutz" className="text-primary hover:underline">Datenschutzerklärung</a>.
                  </p>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setShowSettings(true)}
                    className="text-gray-600 hover:text-primary flex items-center space-x-2"
                  >
                    <Settings size={16} />
                    <span>Einstellungen</span>
                  </button>
                  <button
                    onClick={handleAcceptAll}
                    className="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary/90 transition-colors"
                  >
                    Alle akzeptieren
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">Cookie-Einstellungen</h3>
                  <button
                    onClick={() => setShowSettings(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X size={20} />
                  </button>
                </div>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">Notwendige Cookies</p>
                      <p className="text-sm text-gray-500">Erforderlich für die Grundfunktionen der Website</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.necessary}
                      disabled
                      className="rounded text-primary"
                    />
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">Analyse Cookies</p>
                      <p className="text-sm text-gray-500">Helfen uns die Website zu verbessern</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.analytics}
                      onChange={(e) => setConsent({ ...consent, analytics: e.target.checked })}
                      className="rounded text-primary"
                    />
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">Marketing Cookies</p>
                      <p className="text-sm text-gray-500">Ermöglichen personalisierte Werbung</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.marketing}
                      onChange={(e) => setConsent({ ...consent, marketing: e.target.checked })}
                      className="rounded text-primary"
                    />
                  </div>
                </div>
                
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setShowSettings(false)}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    Abbrechen
                  </button>
                  <button
                    onClick={handleSaveSettings}
                    className="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary/90 transition-colors"
                  >
                    Einstellungen speichern
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieBanner;