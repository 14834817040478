import React from 'react';
import { motion } from 'framer-motion';
import { Code, Globe, Smartphone, Palette, Server, Shield } from 'lucide-react';
import ServiceCard from './ServiceCard';
import { Service } from '../../types';

const services: Service[] = [
  {
    icon: <Code className="w-8 h-8 text-primary" />,
    title: "Webdesign & Entwicklung",
    description: "Professionelles Webdesign aus Österreich mit 15+ Jahren Erfahrung. Responsive Websites, optimiert für alle Endgeräte. Modernste Technologien für maximale Performance."
  },
  {
    icon: <Globe className="w-8 h-8 text-primary" />,
    title: "Full-Stack Development",
    description: "Skalierbare Webanwendungen mit React, Vue.js und Node.js. Sichere Backend-Systeme, API-Entwicklung und Datenbank-Integration. SSL-Verschlüsselung & DSGVO-konform."
  },
  {
    icon: <Smartphone className="w-8 h-8 text-primary" />,
    title: "App Entwicklung",
    description: "Native und Cross-Platform Apps für iOS und Android. Entwickelt mit NativeScript für optimale Performance. Inklusive Wartung und regelmäßige Updates."
  },
  {
    icon: <Palette className="w-8 h-8 text-primary" />,
    title: "UI/UX Design",
    description: "Nutzerorientierte Gestaltung mit Fokus auf Conversion. A/B-Testing und Analyse für optimale Ergebnisse. Barrierefreie Umsetzung nach WCAG-Standards."
  },
  {
    icon: <Server className="w-8 h-8 text-primary" />,
    title: "Hosting & Wartung",
    description: "Managed Hosting in österreichischen Rechenzentren. Tägliche Backups, Monitoring & Security-Updates. 99,9% Uptime-Garantie & technischer Support."
  },
  {
    icon: <Shield className="w-8 h-8 text-primary" />,
    title: "SEO & Performance",
    description: "Ganzheitliche SEO-Optimierung für bessere Rankings. Core Web Vitals Optimierung & technisches SEO. Local SEO für österreichische Unternehmen."
  }
];

const Services = () => {
  return (
    <section id="services" className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6">Unsere Leistungen</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Von der Konzeption bis zur Umsetzung - professionelle Webentwicklung aus Österreich für Ihren digitalen Erfolg.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;