import React from 'react';
import { motion } from 'framer-motion';

const HeroHeading = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8 }}
      className="lg:text-left text-center"
    >
      <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight">
        Webdesign & Webentwicklung aus Österreich
      </h1>
      <p className="text-xl text-white/90 mb-8">
        Professionelle Full-Stack Entwicklung & SEO für Ihren Geschäftserfolg. 15+ Jahre Expertise in Wien und ganz Österreich.
      </p>
      <ul className="text-white/80 space-y-2 mb-8 text-lg">
        <li>✓ Responsive Webdesign & Mobile-First</li>
        <li>✓ Moderne Technologien & Framework-Expertise</li>
        <li>✓ SEO-Optimierung & Performance</li>
      </ul>
    </motion.div>
  );
};

export default HeroHeading;