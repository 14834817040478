import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import NavigationItem from './NavigationItem';
import { useScrollSpy } from '../../hooks/useScrollSpy';
import { validateInternalLinks } from '../../utils/routeValidator';

const navigationItems = [
  { href: '/#services', label: 'Leistungen', internal: true },
  { href: '/#industries', label: 'Branchen', internal: true },
  { href: '/#process', label: 'Ablauf', internal: true },
  { href: '/#pricing', label: 'Preise', internal: true },
  { href: '/#contact', label: 'Kontakt', internal: true }
];

const Navigation = () => {
  const location = useLocation();
  const activeSection = useScrollSpy(
    navigationItems
      .filter(item => item.internal)
      .map(item => item.href.replace('/#', ''))
  );

  React.useEffect(() => {
    // Validate internal links on mount
    const issues = validateInternalLinks(document);
    if (issues.length > 0) {
      console.warn('Navigation validation issues:', issues);
    }
  }, []);

  return (
    <nav className="hidden md:block">
      <motion.ul 
        className="flex items-center space-x-8"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {navigationItems.map((item) => (
          <NavigationItem
            key={item.href}
            href={item.href}
            isActive={item.internal && activeSection === item.href.replace('/#', '')}
            internal={item.internal}
          >
            {item.label}
          </NavigationItem>
        ))}
      </motion.ul>
    </nav>
  );
};

export default Navigation;