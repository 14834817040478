import React from 'react';
import IndustryShowcase from './IndustryShowcase';

const Industries = () => {
  return (
    <section id="industries" className="py-24 bg-white">
      <IndustryShowcase />
    </section>
  );
};

export default Industries;