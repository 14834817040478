import React, { createContext, useContext, useState, useEffect } from 'react';
import { FormData, FormContextType } from '../../types/onboarding';
import { validateField } from '../../utils/validation';

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [saveStatus, setSaveStatus] = useState('');
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    targetAudience: '',
    userNeeds: '',
    userDevices: [],
    userContext: '',
    technicalSkills: '',
    businessGoals: '',
    marketingGoals: '',
    measurableObjectives: '',
    timelineGoals: '',
    mainCompetitors: '',
    competitorStrengths: '',
    competitorWeaknesses: '',
    uniqueAdvantage: '',
    coreBenefit: '',
    targetGroup: '',
    uniqueFeature: '',
    pitchSummary: '',
    personaAge: '',
    personaEducation: '',
    personaJob: '',
    personaGoals: '',
    personaChallenges: '',
    personaBuying: '',
    mainSections: '',
    subPages: '',
    specialFeatures: '',
    mobileNav: '',
    mainCTA: '',
    currentContent: '',
    contentToUpdate: '',
    technicalIssues: '',
    seoStatus: '',
    analyticsData: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('onboardingData');
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        setFormData(parsedData);
      } catch (e) {
        console.error('Error loading saved data:', e);
      }
    }
  }, []);

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      localStorage.setItem('onboardingData', JSON.stringify(formData));
      setSaveStatus('Automatisch gespeichert');
      setTimeout(() => setSaveStatus(''), 2000);
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [formData]);

  const value = {
    currentStep,
    setCurrentStep,
    saveStatus,
    setSaveStatus,
    errors,
    setErrors,
    files,
    setFiles,
    submitted,
    setSubmitted,
    formData,
    setFormData,
    validateField
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};