import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { Industry } from './types';
import { 
  AutoRepairExample,
  BuildingServicesExample,
  BarbershopExample,
  ConstructionExample,
  RestaurantExample,
  HotelExample,
  FitnessExample,
  MedicalExample,
  RetailExample,
  TaxExample,
  LawExample
} from './examples';

interface IndustryModalProps {
  industry: Industry | null;
  onClose: () => void;
}

const EXAMPLES_MAP: Record<string, React.FC> = {
  barbershop: BarbershopExample,
  construction: ConstructionExample,
  auto: AutoRepairExample,
  technician: BuildingServicesExample,
  restaurant: RestaurantExample,
  hotel: HotelExample,
  fitness: FitnessExample,
  medical: MedicalExample,
  retail: RetailExample,
  tax: TaxExample,
  law: LawExample
};

const IndustryModal: React.FC<IndustryModalProps> = ({ industry, onClose }) => {
  if (!industry) return null;

  const ExampleComponent = EXAMPLES_MAP[industry.id];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="relative w-full max-w-6xl max-h-[90vh] overflow-y-auto bg-white rounded-xl"
          onClick={e => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-10 p-2 bg-white/90 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
            aria-label="Close modal"
          >
            <X className="w-6 h-6" />
          </button>
          
          {ExampleComponent && <ExampleComponent />}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default IndustryModal;