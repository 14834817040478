import React from 'react';
import { motion } from 'framer-motion';
import { ClipboardCheck, PenTool, HardHat, CheckSquare } from 'lucide-react';

const steps = [
  {
    icon: ClipboardCheck,
    title: "Planung",
    description: "Detaillierte Projektplanung und Kostenanalyse"
  },
  {
    icon: PenTool,
    title: "Entwurf",
    description: "Architektonische Gestaltung und technische Planung"
  },
  {
    icon: HardHat,
    title: "Ausführung",
    description: "Professionelle Bauausführung mit Qualitätskontrolle"
  },
  {
    icon: CheckSquare,
    title: "Übergabe",
    description: "Termingerechte Fertigstellung und Übergabe"
  }
];

const ProcessSection = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Unser Prozess</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative"
            >
              <div className="bg-primary/10 p-6 rounded-xl">
                <step.icon className="w-12 h-12 text-primary mb-4" />
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-1/2 right-0 w-full h-0.5 bg-primary/20 transform translate-x-1/2">
                  <div className="absolute right-0 top-1/2 w-3 h-3 bg-primary rounded-full transform -translate-y-1/2" />
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;