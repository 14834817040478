import React from 'react';
import { Shield } from 'lucide-react';

const LegalNotices = () => {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className="flex items-start space-x-3">
          <Shield className="w-5 h-5 text-primary mt-1 flex-shrink-0" />
          <div className="space-y-4">
            <p className="text-gray-700">
              Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, 
              diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung 
              von bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf 
              schriftlicher Zustimmung des Webseitenbetreibers.
            </p>
            <p className="text-gray-700">
              Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser 
              Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten 
              Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>
            <p className="text-gray-700">
              Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt 
              beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, 
              bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger 
              Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalNotices;