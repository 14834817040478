import React from 'react';
import { cn } from '../../utils/cn';

interface FormFieldProps {
  label: string;
  name: string;
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  placeholder?: string;
  rows?: number;
  error?: string;
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  placeholder = '',
  rows = 3,
  error = '',
  required = false
}) => (
  <div className="mb-4">
    <label className="block font-medium mb-2">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    {type === 'textarea' ? (
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        className={cn(
          "w-full p-2 border rounded focus:ring-2 focus:ring-blue-500",
          error ? 'border-red-500' : 'border-gray-300'
        )}
        rows={rows}
        placeholder={placeholder}
      />
    ) : type === 'file' ? (
      <input
        type="file"
        name={name}
        onChange={onChange}
        className="w-full p-2 border rounded"
        accept=".pdf,.doc,.docx,image/*"
      />
    ) : (
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={cn(
          "w-full p-2 border rounded focus:ring-2 focus:ring-blue-500",
          error ? 'border-red-500' : 'border-gray-300'
        )}
        placeholder={placeholder}
      />
    )}
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

export default FormField;