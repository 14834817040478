import React from 'react';
import { Save, Send } from 'lucide-react';
import { useForm } from './FormContext';
import { validateStep } from '../../utils/validation';

const FormNavigation = () => {
  const { 
    currentStep, 
    setCurrentStep, 
    formData, 
    setErrors, 
    setSubmitted 
  } = useForm();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const stepErrors = validateStep(currentStep, formData);
    
    if (Object.keys(stepErrors).length === 0) {
      if (currentStep < 7) {
        setCurrentStep(currentStep + 1);
      } else {
        setSubmitted(true);
        // Here you would typically submit the form data to your backend
        console.log('Form submitted:', formData);
      }
    } else {
      setErrors(stepErrors);
    }
  };

  return (
    <div className="mt-8 flex justify-between">
      <button
        type="button"
        onClick={() => setCurrentStep(Math.max(currentStep - 1, 1))}
        className={cn(
          "px-4 py-2 rounded",
          currentStep === 1
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-gray-500 text-white hover:bg-gray-600"
        )}
        disabled={currentStep === 1}
      >
        Zurück
      </button>

      <div className="flex space-x-4">
        <button
          type="button"
          onClick={() => localStorage.setItem('onboardingData', JSON.stringify(formData))}
          className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
        >
          <Save className="w-4 h-4 inline-block mr-2" />
          Zwischenspeichern
        </button>

        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          {currentStep === 7 ? (
            <>
              <Send className="w-4 h-4 inline-block mr-2" />
              Absenden
            </>
          ) : (
            'Weiter'
          )}
        </button>
      </div>
    </div>
  );
};

export default FormNavigation;