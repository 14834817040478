import React from 'react';
import { useForm } from '../FormContext';
import FormField from '../FormField';

const Navigation = () => {
  const { formData, setFormData, errors } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Navigationsstruktur</h3>
      
      <FormField
        label="Welche Hauptbereiche soll die Website haben?"
        name="mainSections"
        value={formData.mainSections}
        onChange={handleChange}
        type="textarea"
        placeholder="z.B. Produkte, Services, Über uns..."
        error={errors.mainSections}
        required
      />
      
      <FormField
        label="Welche wichtigen Unterseiten sind geplant?"
        name="subPages"
        value={formData.subPages}
        onChange={handleChange}
        type="textarea"
        placeholder="Listen Sie die wichtigsten Unterseiten auf..."
      />
      
      <FormField
        label="Welche Spezialfunktionen werden benötigt?"
        name="specialFeatures"
        value={formData.specialFeatures}
        onChange={handleChange}
        type="textarea"
        placeholder="z.B. Shop, Login-Bereich, Suche..."
      />

      <FormField
        label="Wie soll die mobile Navigation gestaltet werden?"
        name="mobileNav"
        value={formData.mobileNav}
        onChange={handleChange}
        type="textarea"
        placeholder="Beschreiben Sie Ihre Vorstellungen zur mobilen Menüführung..."
      />

      <FormField
        label="Wichtige Call-to-Actions"
        name="mainCTA"
        value={formData.mainCTA}
        onChange={handleChange}
        type="textarea"
        placeholder="Welche wichtigen Aktionen sollen Besucher durchführen?"
      />
    </div>
  );
};

export default Navigation;